<!-- 应收账款预警表 -->
<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  financeWarningArEarlyWarningReport,
  financeWarningSumArEarlyWarningReport,
} from "@/api/arap/index";

export default {
  name: "arAlertDetail",
  components: { TablePage },
  data() {
    return {
      options: {
        uuid: "9ca1f25c-c310-7901-1efd-c74af0763f1e",
        mutiSelect: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10,
        },
        getListApi: financeWarningArEarlyWarningReport,
        getSummaryApi: financeWarningSumArEarlyWarningReport,
        exportOption: {
          show: true,
          exportApi: "exportArEarlyWarningReport",
          exportName: "应收账款预警表",
          timeout: 60 * 5 * 1000,
        },
        body: {},
        listNo: true,
        title: "单据信息",
        check: [],
        rowKey: "billId",
        search: [
          {
            label: "单据日期",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          this.$select({
            key: "listCustomer",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listSimpleQuery",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listSaleEmployee",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        columns: [
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 155,
          },
          {
            prop: "billTypeName",
            label: "单据类型",
            minWidth: 155,
          },
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 180,
          },
          {
            prop: "partnerNo",
            label: "客户编号",
            formatter: (v, row) =>
              row.partnerInfo?.partnerNo ? row.partnerInfo?.partnerNo : "",
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "客户名称",
            formatter: (v, row) =>
              row.partnerInfo?.partnerName ? row.partnerInfo?.partnerName : "",
            minWidth: 120,
          },
          {
            prop: "deptNo",
            label: "部门编码",
            formatter: (v, row) => (row.deptInfo?.deptNo ? row.deptInfo?.deptNo : ""),
            minWidth: 120,
          },
          {
            prop: "deptName",
            label: "部门名称",
            formatter: (v, row) => (row.deptInfo?.deptName ? row.deptInfo?.deptName : ""),
            minWidth: 120,
          },
          {
            prop: "employeeNo",
            label: "业务员编码",
            formatter: (v, row) =>
              row.saleEmployeeInfo?.employeeNo ? row.saleEmployeeInfo?.employeeNo : "",
            minWidth: 120,
          },
          {
            prop: "employeeName",
            label: "业务员名称",
            formatter: (v, row) =>
              row.saleEmployeeInfo?.employeeName
                ? row.saleEmployeeInfo?.employeeName
                : "",
            minWidth: 120,
          },
          {
            prop: "billMoney",
            label: "应收金额",
            minWidth: 140,
          },
          {
            prop: "settleMoney",
            label: "已核销金额",
            minWidth: 140,
          },
          {
            prop: "notSettleMoney",
            label: "未核销金额",
            minWidth: 140,
          },
          {
            prop: "settlementDate",
            label: "结算日期",
            minWidth: 140,
          },
          {
            prop: "overDays",
            label: "超期天数",
            minWidth: 140,
          },
        ],
        list: [],
        summary: ["billMoney", "settleMoney", "notSettleMoney"],
      },
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped></style>
